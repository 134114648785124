import React, {useCallback, useContext, useEffect, useState} from 'react';
import AdminDash from "../../layout/admin";
import Table from "../../components/Table";
import AxiosInstance from "../../helpers/axiosInstance";
import {UserProvider} from "../../hoc/userProvider";
import {FiSearch} from "react-icons/fi";
import Modal from "../../components/Modal";
import Editor from "../../components/Editor";
import {toast,ToastContainer} from "react-toastify";

const AllPosts = () => {
    const [posts,setPosts] = useState([])
    const [offset,setOffset] = useState(1)
    const [limit,setLimit] = useState(10)
    const [search,setSearch] = useState('')
    const [showEdit,setShowEdit] = useState(false);
    const [showDelete,setShowDelete] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [selectedPost,setSelectedPost] = useState({});

    const [numOfPages,setNumOfPages] = useState(1)
    const user = useContext(UserProvider) || JSON.parse(localStorage.getItem('user_id')) ;
    function _createPagination() {
        let _list = [];
        for (let i=1;i<=numOfPages;i++){
            _list.push(<span onClick={()=>getPg(i)} className={offset===i?'h6 pg pg-active':'h7 pg'} key={i}>{i}</span>)
        }
        return _list;
    }

    function getPg(pg){
        setOffset(pg);
    }
    function prev() {
        if (offset>1){
            setOffset(offset - 1)
        }
    }
    function nextPage() {
            setOffset(offset + 1)
    }
    function selectPost(id) {
        const index = posts.findIndex(n=>n._id===id);
        setSelectedPost(posts[index]);
    }
    const  getAllPosts = useCallback(() =>{
        AxiosInstance.get(`/art/get/all?offset=${offset}&limit=${limit}`,{headers:{
            authorization: localStorage.getItem('user_ton')
            }})
            .then(res=>{
                setPosts(res.data.data.docs)
                setNumOfPages(res.data.data.totalPages)
            })
            .catch(err=>{
                console.log(err)
            })
    },[user.token,limit,offset,setNumOfPages,setPosts]);

    function deletePost() {
        AxiosInstance.delete(`/art/p/${selectedPost._id}/del`,{
            headers:{
                authorization: user.token
            }
        })
            .then(res=>{
                toast.info(res.data.msg);
                setTimeout(()=>{
                    setShowDelete(false)
                    setRefresh(!refresh)
                },1500)
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    }

    useEffect(()=>{
        getAllPosts();
    },[getAllPosts,refresh])
    return (
        <AdminDash>
            <h4 className="h4">All Posts</h4>
            <p className="h6">View all your posts</p>
            <div className="mgT4  post-box">
                <form>
                    <div className="form-input-box " style={{width:'100%',display:'flex'}}>
                        <input value={search} onChange={(e)=>setSearch(e.target.value)} className="form-input" placeholder={'Search posts by name'}/>
                        <button style={{border:'none',backgroundColor:'inherit'}}><FiSearch size={24}/></button>
                    </div>

                    <div className="display_flex">
                        <label className="h6">Filter </label>
                        <div className="form-input-box width15">

                            <select className={'form-select'} value={limit} onChange={(e)=>setLimit(e.target.value)}>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                    </div>
                </form>

                {
                    showDelete?  <Modal closeModal={setShowDelete}>
<ToastContainer/>
                        <h5 className="h6">Are You Sure You want to delete Post?</h5>

                        <button className="btn btn-red mgT4" onClick={deletePost}>Delete</button>


                    </Modal>:null
                }
                {
                    showEdit?  <Modal closeModal={setShowEdit}>
                            <h6 className="h6">Edit Post</h6>
                        <Editor cb={()=>{
                            setTimeout(()=>{
                                setShowEdit(false)
                            },1500)
                            setRefresh(!refresh)}} owner={selectedPost.owner} title_={selectedPost.title} id_={selectedPost._id} image_={selectedPost.image} description_={selectedPost.description}/>

                    </Modal>:null
                }
             <Table type={'light-table'} headers={['Title','Description','Image','']} body={
                 <>
                     {
                         posts.length>0? posts.map((p)=><tr className={'h7'} key={p._id}>
                             <td>{p.title}</td>
                             <td dangerouslySetInnerHTML={{__html: p.description}}/>
                             <td>{p.image?<img className={'fluidImg'} alt={p.title} src={`http://localhost:4000/${p.image.replace("uploads","").replace(/\\/g, '/')}`} controls autoPlay/>
                             :"no Image"}</td>
                             {/*<td className={p?.status==='pending'?'blueText h5':p?.status==='active'?'greenText':"redText"}>{p?.status}</td>*/}
                             <td>

                                 <span  className="btn btn-primary" onClick={()=>{
                                     selectPost(p._id);
                                     setShowEdit(true);
                                 }}>Edit</span>
                                 <span  className="btn btn-red"  onClick={()=>{
                                     selectPost(p._id);
                                     setShowDelete(true);
                                 }}>Delete</span>
                                 {/*<span className="btn btn-dark">Suspend</span>*/}

                             </td>
                         </tr>):<tr className={'emptyTable'} ><h6 className="h6"><td colSpan={4} className={'tr-nodata'}>NO Data Found</td></h6></tr>
                     }
                 </>
                }/>
            </div>
            <div className="paginator">
                <div className={'mgV2'}>
                    <span className="h6 pg-active-tab" onClick={prev}>Prev</span>{
                    numOfPages!==1?_createPagination():<span className={'h7 pg-active pg'} >1</span>
                }  <span className="h6 pg-active-tab" onClick={nextPage}>NEXT</span>
                </div>
            </div>

        </AdminDash>
    );
};

export default AllPosts;