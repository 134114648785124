import React from 'react';
import TextEditor from "../../../components/TextEditor";
import AdminDash from "../../../layout/admin";
const AddArticle = () => {

    return (
        <AdminDash>
            <h4 className="h4">Create Post</h4>
            <p className="h6 mgT1">Use this text editor to create a new Post</p>
            <TextEditor/>
        </AdminDash>
    );
};

export default AddArticle;