import React from 'react';
let userDetails ={
    isLoggedIn: false,
    token: '',
    user:{},
    logOut:()=>{},
    login: ()=>{}
}


export const UserProvider = React.createContext(userDetails)

