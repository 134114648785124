import React, {useContext, useState} from 'react';
import ReactQuill from 'react-quill';
import {toast,ToastContainer} from "react-toastify";
import {UserProvider} from "../hoc/userProvider";
import AxiosInstance from "../helpers/axiosInstance";
import 'quill/dist/quill.snow.css';
import Loader from "../hoc/loader";
const TextEditor = () => {
    const user = useContext(UserProvider)
    const [title,setTitle] = useState('');
    const [loading,setLoading] = useState(false);
    const [description,setDescription] = useState('');
    const [images,setImages] = useState([])
    const [image,setImage] = useState(new File([], "", undefined));
    const userInfo = JSON.parse(user.user) || JSON.parse(localStorage.getItem('user_id'));
    function createPost(){



        const formData = new FormData();
        formData.append('image',image)
        formData.append('title',title)
        formData.append('description',description)
        formData.append('owner',userInfo._id)
       if (image!==null && title.length>5 && description.length>5){
           setLoading(true)
           AxiosInstance.post('/art/create',formData,{
               headers:{
                   authorization: localStorage.getItem('user_ton')
               }
           })
               .then(res=>{
                   setLoading(false)
                   toast(res.data.msg);
                   setImages([]);
                   setTitle('')
                   setDescription('')
                   setImage(new File([], "", undefined))
               })
               .catch(err=>{
                   setLoading(false)
                   toast.error(err.response.data.msg)
               })
       }else{
           toast.error('Post details are not properly filled, make sure to add an image and at least one paragraph')
       }
    }

    function selectImage(e) {

        //change the files to dataurl and pass it to an image tag

        const files = e.target.files;
        setImage((files[0]));
        const urlsList = [];
        // console.log(files);
        for (let i = 0;i<files.length;i++){
            let res = getBase64(files[i])
            urlsList.push(res)
        }
        console.log(urlsList)
        setImages(urlsList);
    }

    function getBase64(file) {

        return  window.URL.createObjectURL(file)

    }
    return (
       <div className={'mgT4'}>
           <ToastContainer/>
           {loading?<Loader/>:null}
           <div className="width60">
               <label className="h6">Post Title</label>
               <div className="form-input-box">
                   <input className="form-input" placeholder={'title'} value={title} onChange={(e)=>setTitle(e.target.value)}/>
               </div>
               <label className={'h6'}>File</label>
               <div className="form-file centerText mgT2 mgB4">

                   <input  onChange={selectImage}   id='file_type_input' type='file' className='file-input'/>
                   <label className='h5' htmlFor='file_type_input'>


                       <>
                           {
                               images.length>0?
                                   <div className=" post_image-box">
                                       {images.length>0?images.map((url,id)=> <img key={id} className='post_img'
                                                                                   alt={''} src={url}/>)
                                           :null}
                                   </div>

                                   :`Click to add Image`
                           }

                       </>
                   </label>
               </div>
           </div>
           <div style={{ width: '100%', height: 300 }}>
               <ReactQuill  value={description} placeholder={'Write Here'} onChange={(e)=>{setDescription(e)}} theme="snow" />
           </div>


           <button  className={'btn btn-primary'} onClick={createPost}>Create Post</button>
       </div>
    );
};

export default TextEditor;