import React from 'react';
import Navigation from "../components/Navigation";
import {BiSearch} from "react-icons/bi";
import '../sass/pages/faq.scss'
import FaqItem from "./faqItem";
import {motion} from "framer-motion";
import useScrollToTop from "../helpers/UI/scrollToTop";

const Faqs = () => {

    useScrollToTop();
    const container = {
        hidden:{
            opacity:1,scale:0
        },
        visible:{
            opacity:1,
            scale:1,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }

    return (
        <motion.div className={'overflowHidden'}  initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:.6,ease:[0.23,0.15,0.26,0.93]}}>
            <Navigation/>
            <div className="greyBox center-all mgT8">
                <h4 className="h4">FAQs</h4>
                <div className="faq-search mgT4">
                    <input placeholder={'search'}/>
                    <BiSearch size={28} color={'#f7f7f7'}/>
                </div>

                <motion.div
                    variants={container}
                    initial={'hidden'}
                    animate={'visible'}
                    className="mgT8 faq-section">
                     <FaqItem title={'When was Kereksuk founded?'} body={'Kereksuk was founded in 2010'} />
                     <FaqItem title={'What is Farm Out Of Poverty'} body={'Farm Out Of Poverty (FOOP) is an initiative founded to contribute to poverty alleviation and food security by training a hundred (100) women a year in rice production across Nigeria. '}/>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Faqs;