import React, {useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Logo from "../../../assets/logo.svg";
import {BiShield} from "react-icons/bi";
import Loader from "../../../hoc/loader";
import AxiosInstance from "../../../helpers/axiosInstance";

const Register = () => {
    // const user = useContext(UserContext);
    const [loading,setLoading] = useState(false)
    const [email,setEmail] = useState('')
    const [fullName,setFullName] = useState('')
    const [password,setPassword] = useState('')
    function login(e) {
        e.preventDefault();
        setLoading(true)
        const data = {
            email,
            password,
            fullName
        }

        if (handleValidate(email,'email') && handleValidate(password,'password') && fullName.length>0){
            AxiosInstance.post('/auth/reg',data)
                .then((res)=>{
                    setLoading(false)
                    toast('Success');
                    console.log(res.data)
                }).catch(err=>{

                setLoading(false)
                toast.error(err.response.data.msg)
            })
        }
        else{
            setLoading(false)
            toast.error('Invalid Information')
        }
    }

    function handleValidate(e,type) {
        switch (type){
            case 'email':

                return e.length>0;

            case 'password':

                return e.length>=8;
            default:
                break;
        }
    }
    return (
        <div className='authBox' >
            <ToastContainer/>
            <div className="authBox-card">
                <figure className={'centerText'}>
                    <Link to={'/'}><img alt='piggy logo' src={Logo} className='logo' /></Link>
                </figure>
                <p className="h6 mgT2 greyText centerText">Secure Registration <span className={'h5 greenText'}><BiShield/></span></p>
                <form onSubmit={login} className={'mgT4'}>
                    <label className="h8">Full Name</label>
                    <div className="form-input-box">
                        <input  value={fullName}  onChange={(e)=>{


                            setFullName(e.target.value)}} className={"form-input form-validate"} placeholder={'Full Name '}/>
                    </div>
                    <label className="h8">Email</label>
                    <div className="form-input-box">
                        <input  value={email}  onChange={(e)=>{


                            setEmail(e.target.value)}} className={handleValidate(email,'email')?"form-input form-validate":"form-input form-validate-err"} placeholder={'Email Address'}/>
                    </div>
                    {handleValidate(email,'email')?null:<div><h6 className="h7 redText">Must be a valid Email</h6></div>}

                    <label className="h8">Password</label>
                    <div className="form-input-box">
                        <input  value={password} type={'password'}  onChange={(e)=>setPassword(e.target.value)} className={handleValidate(password,'password')?"form-input form-validate":"form-input form-validate-err"} placeholder={'Password'}/>
                    </div>
                    {handleValidate(password,'password')?null:<div><h6 className="h7 redText">Password must be 8 characters long, containing at least one upper case, a number and Special character (@#*$)</h6></div>}
                    {loading?<Loader/> :
                        <div className="mgT4">
                            <button className="btn btn-dark" style={{width:'100%'}}>Register</button>
                        </div>}
                    <div className="mgT2 centerText">
                        <p className="h7">Already got an account?<Link to={'/login'} className={'link mgL1'}>login</Link></p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;