import React, {useCallback, useEffect, useState} from 'react';
import AdminDash from "../../../layout/admin";
import AxiosInstance from "../../../helpers/axiosInstance";
import {Link} from "react-router-dom";

const IndexPage = () => {
    const [dash,setDash] = useState({})
    const getAll = useCallback(()=>{
        AxiosInstance.get('/dash/info',{
            headers:{
                authorization: localStorage.getItem('user_ton')
            }
        })
            .then(res=>{
                setDash(res.data);
            })
            .catch(err=>{
                console.log(err)
            })
    },[setDash]);
    useEffect(()=>{
        getAll()
    },[getAll])
    return (
        <AdminDash>
            <h4 className="h4">Admin Profile</h4>
            <p className="h6">All activities</p>
            <div className="grid mgT4">
                {dash.hasOwnProperty('art')?<div className="grid-item2_4 border-card">
                    <h5 className="h4">{dash?.art}</h5>
                    <p className="mgT2 h6">Total Posts</p>
                </div>:null}
                {dash.hasOwnProperty('users')?<div className="grid-item2_4 border-card">
                    <h5 className="h4">{dash?.users}</h5>
                    <p className="mgT2 h6">Total users</p>
                </div>:null}

            </div>
            <div className="grid mgT2">
                {dash.hasOwnProperty('img')?<div className="grid-item2_4 border-card">
                    <h5 className="h4">{dash?.img}</h5>
                    <p className="mgT2 h6">Total Photos</p>
                </div>:null}
                {dash.hasOwnProperty('vid')?<div className="grid-item2_4 border-card">
                    <h5 className="h4">{dash?.vid}</h5>
                    <p className="mgT2 h6">Total Videos</p>
                </div>:null}
            </div>
            <div className="mgT4">
                <Link to={'/blog/gallery/'} className="btn-dark btn">View Gallery</Link>
            </div>

        </AdminDash>
    );
};

export default IndexPage;