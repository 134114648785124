import './sass/main.scss';
import React,{useCallback,useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Home from "./pages/home";
import AddArticle from "./pages/blog/admin/addArticle";
import Story from "./pages/Story";
import ObjPage from "./pages/objPage";
import Article from "./pages/blog/articles/article";
import Gallery from "./pages/gallery/gallery";
import Faqs from "./pages/faqs";
import BlogHome from "./pages/blog/blogHome";
import Register from "./pages/blog/auth/register";
import Login from "./pages/blog/auth/login";
import IndexPage from "./pages/blog/admin/indexPage";
import UploadToGallery from "./pages/blog/admin/uploadToGallery";
import AllPosts from "./pages/blog/allPosts";
import Users from "./pages/blog/admin/users";
import {UserProvider} from "./hoc/userProvider";
import FullGallery from "./pages/blog/fullGallery";

import {AnimatePresence} from "framer-motion";
import ProtectedRoute from "./hoc/protectedRoute";
import YorfanPage from "./pages/yorfan/yorfanPage";
function App() {
   const navigate = useNavigate();
   const [user,setUser] = useState(localStorage.getItem('user_id')||{});
   const [token,setToken] = useState(localStorage.getItem('user_ton')||null);

   const logOut = useCallback (()=> {
      localStorage.clear();
      navigate('/blog/',{replace:true});
   },[navigate])
   function login(data,token) {
      setUser(data);
      setToken(token);
      localStorage.setItem('user_ton',token)
      localStorage.setItem('user_id',JSON.stringify(data));
      //navigate to dashboard
      navigate('/blog/admin',{replace:true});
   }
  return (

  <UserProvider.Provider value={{token,user,logOut,login}}>
     <AnimatePresence initial={true} exitBeforeEnter>
     <Routes>
        <Route path={'/'} element={<Home/>}/>
        <Route path={'/obj'} element={<ObjPage/>}/>
        <Route path={'/story'} element={<Story/>}/>
        <Route path={'/gallery'} element={<Gallery/>}/>
        <Route path={'/blog'} element={<BlogHome/>}/>
        <Route path={'/register'} element={<Register/>}/>
        <Route path={'/login'} element={<Login/>}/>
        <Route path={'/faqs'} element={<Faqs/>}/>

        <Route path={'/yorfan'} element={<YorfanPage/>}/>
        {/*ADMIN ROUTES*/}
        <Route path={'/blog/:title/:id'} element={<Article/>}/>
        <Route path={'/blog/add'} element={<ProtectedRoute><AddArticle/></ProtectedRoute>}/>
        <Route path={'/blog/admin/'} element={<ProtectedRoute><IndexPage/></ProtectedRoute>}/>
        <Route path={'/blog/gallery/'} element={<ProtectedRoute><FullGallery/></ProtectedRoute>}/>
        <Route path={'/blog/upload/'} element={<ProtectedRoute><UploadToGallery/></ProtectedRoute>}/>
        <Route path={'/blog/allPosts/'} element={<ProtectedRoute><AllPosts/></ProtectedRoute>}/>
        <Route path={'/blog/allUsers/'} element={<ProtectedRoute><Users/></ProtectedRoute>}/>
        <Route path={'/*'} element={<>Error</>}/>
     </Routes>
     </AnimatePresence>
  </UserProvider.Provider>
  );
}

export default App;
