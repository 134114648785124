import React, {useCallback} from 'react';

const useScrollToTop = () => {
    const toTop = useCallback(()=>{
        window.scrollTo(0,0)
    },[])
    React.useEffect(()=>{
       toTop();
    },[toTop])
};

export default useScrollToTop;