import React from 'react';
import {Link} from "react-router-dom";
import Moment from "react-moment";
const BlogCard = ({data}) => {
    return (
        <Link to={`/blog/${data?.title}/${data?._id}`} className={'blogCard'}>
            {
                data.hasOwnProperty('image')?
                    <img alt={data?.title} className={'fluidImg'} src={`http://localhost:4000/${data.image.replace("uploads","").replace(/\\/g, '/')}`}/>
                :null
            }
             <div className="blogCard-bottom">
               <h5 className="h6 greenText">{data?.title}</h5>
               <p className="h7 mgT2 whiteText"><Moment format={'MMM DD, YYYY'}>{data?.createdAt}</Moment></p>
               <p className="h7 mgT1 whiteText">{data?.owner?.fullName}</p>
           </div>

        </Link>
    );
};

export default BlogCard;