import React, {useEffect} from 'react';
import img1 from '../assets/IMG_4058.png'
import img2 from '../assets/IMG_3821 2.png'
import img3 from '../assets/IMG_3597.png'
import pointerDown from '../assets/altpointerdown.svg'
import pointerRight from '../assets/pointerright.svg'
import edge from '../assets/dark square.svg'
import img4 from '../assets/IMG_5966.JPG'
import img5 from '../assets/IMG_5967.JPG'
import {Link} from "react-router-dom";
import useScrollToTop from '../helpers/UI/scrollToTop';
import { Link as ScrollLink} from "react-scroll";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";

const Story = () => {
    useScrollToTop();
    const transition = {
        duration:.6,
        ease: [0.6,0.13,0.23,0.96]
    }
    const controls = useAnimation();
    const { ref, inView } = useInView();
    useEffect(() => {
        console.log(inView)
        if (inView) {
            controls.start('visible').then(()=>{

                // console.log('starting')
            });
        }
        if (!inView) {
            controls.start('hidden').then();
        }
    }, [controls, inView]);


    const container = {
        hidden:{
            opacity:1,scale:0,x:100
        },
        visible:{
            opacity:1,
            scale:1,
            x:0,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }

    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    return (
        <motion.div   initial={{opacity:.2}} animate={{opacity:1}} exit={{opacity:0,x:1000}}>
            <main className="grid ">
                <div className="story-gallery">
                    <div className="scrollGrid">
                        <div className="scrollGrid1_2">
                            <div className="scrollCard anime2" style={{background:`var(--green-gradient)`}}></div>
                            <div className="scrollCard anime2"
                                 style={{backgroundImage: `url(${img1})`,maxHeight: "50rem",height: "50rem"}}
                            >
                            </div>


                            <div className="scrollCard anime2" style={{backgroundColor:"#94A433" }}></div>

                            <div className="scrollCard anime2" style={{backgroundColor:"#29e55f"}}></div>
                        </div>
                        <div className="scrollGrid1_2 ">
                            <div className="scrollCard anime1"
                                 style={{backgroundImage: `url(${img2})`,maxHeight: "50rem",height: "50rem"}}>
                            </div>
                            <div className="scrollCard anime1"
                                 style={{backgroundImage: `url(${img3})`,maxHeight: "50rem",height: "50rem"}}></div>
                            <div className="scrollCard anime1" style={{backgroundColor: "#29e55f"}}></div>
                            <div className="scrollCard anime1" style={{backgroundColor: "#27821C"}}></div>
                        </div>
                    </div>
                    <div className="edgeSqre">
                        <img alt="dark square" src={edge}/>
                    </div>
                </div>
                <motion.div

                    ref={ref}
                    variants={container}
                    animate={controls}
                    className="story-text grid-item-f ">
                    <motion.h4 variants={item} transition={transition} className="h4 mgT8 sec_type">Our Story</motion.h4>
                    <motion.h6 variants={item} transition={transition} className="h6 mgT4 sec_type">Our Mission</motion.h6>
                    <motion.div variants={item} transition={transition} className=" mgT2">
                        <ul className={'story_list sec_type'}>
                            <li>
                                To produce the best quality of paddy worldwide
                            </li>
                        </ul>
                    </motion.div>
                    <motion.h6 variants={item} transition={transition} className="h6 mgT4">Our Vision</motion.h6>
                    <motion.div variants={item} transition={transition} className=" mgT2">
                        <ul className={'story_list sec_type'}>
                            <li>
                              To branch out and expand our reach across Africa and the World at large.
                            </li>
                            <li>
                              To contribute to poverty alleviation and food security by training a hundred (100) women a year.                          in rice production.
                            </li>
                            <li>
                                To incorporate and advance the PGESI (pro poor, gender equality, social inclusion and climate change) goals in all our activities.
                            </li>
                        </ul>
                    </motion.div>
                    <motion.h6 variants={item} transition={transition} className="h6 mgT4">Our Values</motion.h6>
                    <motion.div variants={item} transition={transition} className=" mgT2">
                        <motion.ul className={'story__list'}>
                            <li  >
                                Integrity
                            </li>
                            <li  >

                                Relevance


                            </li>
                            <li  >
                                Excellence
                            </li>
                            <li  >
                                Authenticity
                            </li>
                            <li  >
                                Teamwork
                            </li>
                            <li  >
                                Uniqueness
                            </li>
                        </motion.ul>
                    </motion.div>

                    <div >
                        <ScrollLink to={'foop'} className="mgT12 " style={{display: "block"}} >
                            <motion.img whileHover={{scale:1.1,y:20}} transition={transition} alt="pointer down" src={pointerDown} className=" "/>
                        </ScrollLink>
                    </div>
                </motion.div>

            </main>
            <div className="foop mgT12">
                <div className="center-all">
                    <div className="grid">
                        <div className="grid-item2_4">
                            <svg className="foopIcon" width="231" height="855" viewBox="0 0 231 855" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect y="112" width="122" height="525" fill="#79E76C"/>
                                <path
                                    d="M155.268 68.256V91.82H106.076V117.276H142.884V140.152H106.076V189H76.664V68.256H155.268Z"
                                    fill="black"/>
                                <path
                                    d="M133.768 358.204C122.416 358.204 111.981 355.567 102.464 350.292C93.0613 345.017 85.5507 337.679 79.932 328.276C74.428 318.759 71.676 308.095 71.676 296.284C71.676 284.473 74.428 273.867 79.932 264.464C85.5507 255.061 93.0613 247.723 102.464 242.448C111.981 237.173 122.416 234.536 133.768 234.536C145.12 234.536 155.497 237.173 164.9 242.448C174.417 247.723 181.871 255.061 187.26 264.464C192.764 273.867 195.516 284.473 195.516 296.284C195.516 308.095 192.764 318.759 187.26 328.276C181.756 337.679 174.303 345.017 164.9 350.292C155.497 355.567 145.12 358.204 133.768 358.204ZM133.768 331.372C143.4 331.372 151.083 328.161 156.816 321.74C162.664 315.319 165.588 306.833 165.588 296.284C165.588 285.62 162.664 277.135 156.816 270.828C151.083 264.407 143.4 261.196 133.768 261.196C124.021 261.196 116.224 264.349 110.376 270.656C104.643 276.963 101.776 285.505 101.776 296.284C101.776 306.948 104.643 315.491 110.376 321.912C116.224 328.219 124.021 331.372 133.768 331.372Z"
                                    fill="black"/>
                                <path
                                    d="M133.768 526.204C122.416 526.204 111.981 523.567 102.464 518.292C93.0613 513.017 85.5507 505.679 79.932 496.276C74.428 486.759 71.676 476.095 71.676 464.284C71.676 452.473 74.428 441.867 79.932 432.464C85.5507 423.061 93.0613 415.723 102.464 410.448C111.981 405.173 122.416 402.536 133.768 402.536C145.12 402.536 155.497 405.173 164.9 410.448C174.417 415.723 181.871 423.061 187.26 432.464C192.764 441.867 195.516 452.473 195.516 464.284C195.516 476.095 192.764 486.759 187.26 496.276C181.756 505.679 174.303 513.017 164.9 518.292C155.497 523.567 145.12 526.204 133.768 526.204ZM133.768 499.372C143.4 499.372 151.083 496.161 156.816 489.74C162.664 483.319 165.588 474.833 165.588 464.284C165.588 453.62 162.664 445.135 156.816 438.828C151.083 432.407 143.4 429.196 133.768 429.196C124.021 429.196 116.224 432.349 110.376 438.656C104.643 444.963 101.776 453.505 101.776 464.284C101.776 474.948 104.643 483.491 110.376 489.912C116.224 496.219 124.021 499.372 133.768 499.372Z"
                                    fill="black"/>
                                <path
                                    d="M168.684 640.128C168.684 647.123 167.079 653.544 163.868 659.392C160.657 665.125 155.727 669.769 149.076 673.324C142.425 676.879 134.169 678.656 124.308 678.656H106.076V722H76.664V601.256H124.308C133.94 601.256 142.081 602.919 148.732 606.244C155.383 609.569 160.371 614.156 163.696 620.004C167.021 625.852 168.684 632.56 168.684 640.128ZM122.072 655.264C127.691 655.264 131.876 653.945 134.628 651.308C137.38 648.671 138.756 644.944 138.756 640.128C138.756 635.312 137.38 631.585 134.628 628.948C131.876 626.311 127.691 624.992 122.072 624.992H106.076V655.264H122.072ZM197.756 723.376C192.596 723.376 188.353 721.885 185.028 718.904C181.817 715.808 180.212 712.024 180.212 707.552C180.212 702.965 181.817 699.124 185.028 696.028C188.353 692.932 192.596 691.384 197.756 691.384C202.801 691.384 206.929 692.932 210.14 696.028C213.465 699.124 215.128 702.965 215.128 707.552C215.128 712.024 213.465 715.808 210.14 718.904C206.929 721.885 202.801 723.376 197.756 723.376Z"
                                    fill="black"/>
                            </svg>
                        </div>
                        <div className="grid-item2_4" id={'foop'}>
                            <h6 className="h6 mgT8">Our Initiatives</h6>

                            <h4 className="h4 mgT2 sec_type">FARM OUT OF POVERTY</h4>
                            <p className="h6 mgT8 ">Farm Out Of Poverty founded in 2015, is aimed at training a hundred women every year in various areas of rice production.</p>
                            <div className="grid mgT8">
                                <div className="foopCard grid-item2_4">
                                    <img alt="foop" className="fluidImg" src={img5}/>
                       <span className="foopCardIconL">
                           <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
<rect width="50.3728" height="50.3728" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 72.0001)"
      fill="#79E76C"/>
<rect width="32.5051" height="32.5051" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 59.3663)"
      fill="black"/>
</svg>

                       </span>
                                </div>
                                <div className="foopCard grid-item2_4">
                                    <img alt="foop" className="fluidImg" src={img4}/>
                       <span className="foopCardIconR">
                           <svg width="72" height="72" viewBox="0 0 72 72" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
<rect width="50.3728" height="50.3728" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 72.0001)"
      fill="#79E76C"/>
<rect width="32.5051" height="32.5051" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 36.3812 59.3663)"
      fill="black"/>
</svg>
                       </span>
                                </div>
                            </div>
                            <Link to={'/obj'} className="mgT12 " style={{display: "flex",justifyContent: "flex-end"}}  >
                                <img alt="pointer down" src={pointerRight} className=" "/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </motion.div>
    );
};

export default Story;