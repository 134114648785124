import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../../components/Navigation";
import '../../sass/components/blog.scss'
import BlogCard from "./blogCard";
import {Link} from 'react-router-dom'
import AxiosInstance from "../../helpers/axiosInstance";
import Loader from "../../components/loader";
import Moment from "react-moment";
import {FiClock} from "react-icons/fi";
import {motion} from "framer-motion";
import useScrollToTop from "../../helpers/UI/scrollToTop";

const BlogHome = () => {
    useScrollToTop();
    const [latestPost,setLatestPost] = useState({});

    const [numOfPages,setNumOfPages] = useState(1)
    const [loading,setLoading] = useState(false)
    const [limit] = useState(10);
    const [page,setPage] =useState(1);
    const [posts,setPosts] = useState([]);
    const getLatest = useCallback(()=>{
        AxiosInstance.get('/art/p/latest/any')
            .then(res=>{
              setLatestPost(res.data.data[0])
            })
            .catch(err=>{
            })
    },[setLatestPost]);
    function _createPagination() {
        let _list = [];
        for (let i=1;i<=numOfPages;i++){
            _list.push(<span onClick={()=>getPg(i)} className={page===i?'h6 pg pg-active':'h7 pg'} key={i}>{i}</span>)
        }
        return _list;
    }

    function getPg(pg){
        setPage(pg);
    }
    function prev() {
        if (page>1){
            setPage(page - 1)
        }
    }
    function nextPage() {
        setPage(page + 1)
    }
    const getAllPosts = useCallback(()=>{
        setLoading(true)
        AxiosInstance.get(`/art/get/all?offset=${page}&limit=${limit}`)
            .then(res=>{
              setPosts(res.data.data.docs)
                setLoading(false)
                setNumOfPages(res.data.data.totalPages)
            })
            .catch(err=>{

                setLoading(false)
            })
    },[setPosts,page,limit]);
    useEffect(()=>{
        getLatest()
    },[getLatest])

    useEffect(()=>{
        getAllPosts();
    },[getAllPosts]);


    const transition = {
        duration:.6,
        ease: [0.43,0.13,0.23,0.96]
    }


    return (
        <motion.div className={'overflowHidden'} initial={{opacity:0}} animate={{opacity:1}}  transition={transition} exit={{opacity:0}} >
            <Navigation/>
            <div className="Gcenter-all mgT4">
                <h3 className="h3">Blog</h3>
                <p className="h5 mgT2">Latest Post</p>
            </div>
            <div className="greyBox Gcenter-all mgT2">

                <div className="mgT4">
                    {latestPost?.title?<div className={'latestPost'}>
                        <motion.img initial={{scale:.9}}  animate={{
                            y:0,
                                width:'100%',
                            x:0,
                            scale:1
                        }} transition={{delay:.2,...transition}} style={{transform:`translate(-50%,0)`}} src={`http://localhost:4000/${latestPost.image.replace("uploads","").replace(/\\/g, '/')}`} className={'fluidImg'} alt={latestPost?.title}/>
                        <h6 className="h4 mgT2">{latestPost?.title}</h6>
                        <div className="h5 overflowText mgT1" dangerouslySetInnerHTML={{__html:latestPost?.description.slice(0,100) + "..."}}/>
                        <div className="mgT2 _greyCard">
                            <span className="h6  bold"><div className={'h8 greyText'}>Author</div> <span className="bold greenText">{latestPost?.owner?.fullName}</span></span> <span
                            className={'h7 mgL2 greyText'} style={{display:'inline-flex',alignItems:"center"}}><span style={{marginRight:'1.5rem'}}><FiClock size={18} color={'#cccccc'}/></span><Moment format={'MMM DD, YYYY HH:mm '}>{latestPost?.createdAt}</Moment></span>
                        </div>
                        <div className="">
                            <Link to={`/blog/${latestPost?.title}/${latestPost?._id}`} className={'btn btn-dark mgT2'}>Read more</Link>

                        </div>
                              </div>:loading?<Loader/>:<>
                       <h5 className={'h6'}>Not Found</h5>
                    </>}

                </div>
                <div className="mgT8">
                    <h6 className="h5">Other Posts</h6>
                    <div className="postSection mgT4">
                        {
                            posts.length>0?posts.map(p=>{
                                return <BlogCard key={p._id} data={p}/>
                            }):loading?<Loader/>:<div><h5 className="h6">No Post Found</h5></div>
                        }
                    </div>
                </div>

                <div className="paginator">
                    <div className={'mgV2'}>
                        <span className="h6 pg-active-tab" onClick={prev}>Prev</span>{
                        numOfPages!==1?_createPagination():<span className={'h7 pg-active pg'} >1</span>
                    }  <span className="h6 pg-active-tab" onClick={nextPage}>NEXT</span>
                    </div>
                </div>

            </div>
            
        </motion.div>
    );
};

export default BlogHome;