import React from 'react';
import '../sass/components/nav.scss'
import Logo from '../assets/logo.svg';
import {Link} from 'react-router-dom';
import {useLocation} from "react-router-dom";

const Navigation = () => {
    const location = useLocation();

    return (
        <nav className={'navigation'}>
            <figure>
               <Link to={'/'} className={'no-deco'}> <img alt={'kereksuk logo'} src={Logo} className={'logo'}/></Link>
            </figure>
            <ul className={'navigation-list'}>
                <li className="navigation-list-item"><Link to={'/#last'}>About</Link></li>
                <li className="navigation-list-item"><Link to={'/story'}>Story</Link></li>
                <li className="navigation-list-item">

                    <Link to={'/gallery'}>
                        Gallery
                    </Link>
                </li>
  <li className="navigation-list-item">

                    <Link to={'/blog'}>
                        Blog
                    </Link>
                </li>

                <li className="navigation-list-item"><Link to={'/faqs'}>FAQs</Link></li>
                {
                    location.pathname.toString().startsWith('/blog')?<li className={'navigation-list-item'}>
                        <Link to={'/login'} className={'bold greenText'}>Login</Link>
                    </li>:null
                }
            </ul>
        </nav>
    );
};

export default Navigation;